<template>
	<div class="loginCard">
		<template v-if="showLogin">
		<form>

			<div class="row align-items-center">
				<div class="col-sm-4 form-group">
					<label for="username">{{ $t('login.username') }}:</label>
				</div>
				<div class="col-sm-8 form-group">
					<input name="username" id="username"  type="text" v-model.trim="username" v-on:keyup.enter="login" class="form-control" autocorrect="off" autocapitalize="none"/>
				</div>
			</div>

			<div class="row align-items-center">
				<div class="col-sm-4 form-group">
					<label for="password">{{ $t('login.password') }}:</label>
				</div>
				<div class="col-sm-8 form-group">
					<input type="password" v-model.trim="password" v-on:keyup.enter="login" class="form-control"/>
				</div>
			</div>

			<div class="d-flex flex-column align-items-center">
			<div class="text-center login--btn">
				<button @click="login" class="btn btn-primary" type="button" v-on:keyup.enter="login" style="min-width: 280px;">
					<font-awesome-icon icon="sign-in-alt"/>
					{{ $t('login.loginButton') }}
				</button>
			</div>
			</div>

		</form>

		<!--
		<div class="my-4 mx-auto d-flex flex-column align-items-center">
			<div v-if="showAppleButton">
				<button class="loginBtn loginBtn--apple my-2" @click="appleLogin">
					{{ $t('login.appleLoginButton') }}
				</button>
			</div>
			<div>
				<button class="loginBtn loginBtn--facebook my-2" @click="fbLogin">
					{{ $t('login.fbLoginButton') }}
				</button>
			</div>
			<div>
				<GoogleLogin :onSuccess="googleLogin" :onFailure="googleLoginFailure" :params="params" class="loginBtn loginBtn--google my-2" v-if="!nativeGoogle">
					{{ $t('login.googleLoginButton') }}
				</GoogleLogin>
				<button class="loginBtn loginBtn--google my-2" @click="googleNativeLogin" v-if="nativeGoogle">
					{{ $t('login.googleLoginButton') }}
				</button>
			</div>
		</div>
		-->

		<div class="mt-4" v-html="this.$t('login-view.accept-message', [$t('aboutUs.privacyStatement'), $t('aboutUs.termsAndConditions')])"></div>

		<div class="text-right mb-3">
			<router-link to="register">{{ $t('login.registerLink') }}</router-link>
		</div>
		<div class="text-right">
			<router-link to="forgotten-password">{{ $t('login.forgottenPasswordLink') }}</router-link>
		</div>

		</template>
		<LoadingSpinner v-if="!showLogin" />

		<form ref="oauthform" method="post" :action="oauthdata.redirectUri" v-if="oauthdata" style="display: none">
			<input type="hidden" name="state" :value="oauthdata.state" />
			<input type="hidden" name="code" :value="oauthdata.code" />
			<input type="hidden" name="scope" :value="oauthdata.scope" />
			<input type="submit" />
		</form>
	</div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
	name: 'LoginView',
	data() {
		return {
			username: '',
			password: '',
			params: {
				client_id: "1089962333512-c6hpr81nii6l85n3eghhtjkit3rpthia.apps.googleusercontent.com"
			},
			nativeFacebook: 'ios' === window.platform || 'android'===window.platform,
			nativeGoogle: 'ios' === window.platform || 'android'===window.platform,
			oauthdata: null
		};
	},
	props: {
		loggedInUserData: Object
	},
	computed: {
		showLogin() {
			return this.loggedInUserData == null && !(this.mobile && this.$route.query.token);
		},
		showAppleButton() {
			return 'ios' === window.platform;
		},
		mobile() {
			return 'ios' === window.platform || 'android' === window.platform;
		}
	},
	methods: {
		async login() {
			const r = await this.$rest.login(this.username, this.password, this.$route.query.oauth_id);
			if(r) {
				const ut = r.data;
				this.$emit('loginCallback', ut, (userData) => {
					this.username = null;
					this.password = null;
				});

				this.oauthdata = r.extra;
				if(this.oauthdata) {
					setTimeout(() => {
						this.$refs.oauthform.submit();
					}, 0);
				}
			}
		},
		googleNativeLogin() {
			window.loadAppLink('r2gflightserver://google-login');
		},
		googleLogin(googleUser) {
			const token = googleUser.getAuthResponse().id_token
			this.$emit('googleLogin', token)
		},
		appleLogin() {
			window.loadAppLink('r2gflightserver://apple-login');
		}
	},
	async mounted() {
		if(this.mobile && this.$route.query.token) {
			const r = await this.$rest.loginWithToken(this.$route.query.token);
			if(r) {
				const ut = r.data;
				this.$emit('loginCallback', ut, (userData) => {
					this.username = null;
					this.password = null;
				});
			}
		}
		else if(!this.showLogin) {
			const r = await this.$rest.oauthLogin(this.loggedInUserData.username, this.$route.query.oauth_id);
			this.oauthdata = r.extra;
			if(this.oauthdata) {
				setTimeout(() => {
					this.$refs.oauthform.submit();
				}, 0);
			}
		}

		if('ios' !== window.platform) {
			const fbScript = document.createElement('script')
			fbScript.setAttribute('src', 'https://connect.facebook.net/hu_HU/sdk.js#xfbml=1&version=v8.0&appId=3604530536246633&autoLogAppEvents=1')
			document.head.appendChild(fbScript)
		}

		if('ios' !== window.platform) {
			const googleMeta = document.createElement('meta')
			googleMeta.setAttribute("name", "google-signin-client_id")
			googleMeta.setAttribute("content", this.params.client_id)
			document.head.appendChild(googleMeta)

			const googleScript = document.createElement('script')
			googleScript.setAttribute("src", "https://apis.google.com/js/platform.js")
			document.head.appendChild(googleScript)
		}
	},
	components: {
		LoadingSpinner

	},
	emits: ['loginCallback', 'fbLogin', 'googleLogin']
};
</script>

<style>
.loginCard {
	width: 100%;
	max-width: 640px;
	margin: 0 auto;
}

.login--btn{
	margin-top: 1.2rem;
}
</style>
